import React from 'react'
import style from '../App.module.css'

function Feedback() {
  return (
    <div className={style.banner}>
    <h1>Testimonial</h1>
    </div>
  )
}

export default Feedback